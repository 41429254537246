import React from 'react'

export default function CityPageRome() {
  return (
    <section className='pt-8 px-6'>
        <h2 className='uppercase text-2xl pb-4 font-visitdkExtraBold text-primaryBlue'>
            "When in Aarhus..."
        </h2>
    </section>
  )
}